import React, { useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { styled as MuiStyled } from "@mui/material/styles";
import { useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { SIT_DOWN } from "./gql/table/sitDown";
import { DO_ACTION } from "./gql/table/doAction";

const CardView = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.22);
  height: 120px;
  padding: 4px;
  margin: 4px;
  border-radius: 4px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
`;

const Label = styled.label`
  color: #333;
  font-size: 10px;
`;

const Input = MuiStyled(TextField)(() => ({
  "& > label": {
    color: "#222",
    "&.Mui-focused": {
      color: "#222",
    },
  },
  "& .MuiOutlinedInput-root": {
    overflow: "hidden",
    borderRadius: 4,
    color: "#222",
    border: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      border: "none",
      color: "#222",
    },
    "& .Mui-disabled": {
      textFillColor: "#222",
    },
  },
  "& .MuiOutlinedInput-input": {
    textAlign: "center",
    padding: "4px 0",
  },
}));

// {
//     "id": "71a9ba45-6826-4b40-bbb9-1dd8937b4999",
//     "stackSize": 10000,
//     "bet": 0,
//     "raise": null,
//     "holeCards": null,
//     "folded": false,
//     "showCards": false,
//     "left": false,
//     "__typename": "Player"
// }

export enum TPlayerActionTypes {
  BET = "bet",
  CALL = "call",
  CHECK = "check",
  FOLD = "fold",
  RAISE = "raise",
}

export type TSeatData = {
  id: string;
  name: string;
  chips?: number;
  seatNumber: number;
  handCards?: string[];
  bigBlind?: number;
  smallBlind?: number;
  isDealer?: boolean;
};

export type TPlayerProps = {
  playerOriginData?: any;
  tableOriginData?: any;
  availableActingPlayers?: any[];
  id?: string;
  tableId?: string;
  currentBet?: number;
  lastRaise?: number;
  seatNumber: number;
  seatData?: TSeatData;
  isPlayerWin?: boolean;
  isGameStarted?: boolean;
  isAllowActions: boolean;
  isDealer?: boolean;
  bigBlind?: number;
  smallBlind?: number;
  isBigBlindPosition?: boolean;
  isSmallBlindPosition?: boolean;
  onPlayerSitDown?: (seatData: TSeatData) => void;
  onPlayerDoAction?: (actionType: TPlayerActionTypes) => void;
};

// type TSitDownInput = {
//   tableId: string;
//   userId: string;
//   buyIn: number;
//   seatNumber: number;
// };

// type TDoActionInput = {
//   tableId: string;
//   userId: string;
//   action: string;
//   amount: number;
// };

const Player = React.memo(
  ({
    id,
    playerOriginData,
    availableActingPlayers = [],
    seatNumber,
    isPlayerWin,
    isGameStarted,
    isAllowActions,
    onPlayerSitDown,
    onPlayerDoAction,
    tableId,
    isDealer,
    bigBlind,
    smallBlind,
    currentBet,
    lastRaise,
    isBigBlindPosition,
    isSmallBlindPosition,
  }: TPlayerProps) => {
    const playerBet = playerOriginData?.bet || 0;
    const playerRaise = playerOriginData?.raise || 0;
    const playerStackSize = playerOriginData?.stackSize || 0;
    const hasAnotherPlayerToAct = availableActingPlayers.length > 0;
    const playerHoldCards = playerOriginData?.holeCards || [];
    const playerHoldCardsFirst = playerHoldCards[0];
    const playerHoldCardsSecond = playerHoldCards[1];
    const minRaise = (currentBet || 0) + (bigBlind || 0);
    const [raiseInputValue, setRaiseInputValue] = useState(minRaise);
    const [playerNameInputValue, setPlayerNameInputValue] = useState("");
    const [playerStackSizeInputValue, setPlayerStackSizeInputValue] =
      useState(0);
    const slotDisabled = !tableId;
    const isAllowPlayerLeave = !isGameStarted;
    const isAllowBuyIn = !id;
    const defaultAmount = currentBet || bigBlind || 0;

    const [playerSitDown, { loading: playerSitDownLoading }] =
      useMutation(SIT_DOWN);
    const [doAction] = useMutation(DO_ACTION);

    const handlePlayerAction = async (actionType: TPlayerActionTypes) => {
      let amount: number | undefined = 0;
      if (actionType === TPlayerActionTypes.FOLD) {
        amount = undefined;
      } else if (actionType === TPlayerActionTypes.CHECK) {
        amount = undefined;
      } else if (actionType === TPlayerActionTypes.CALL) {
        amount = defaultAmount;
      } else if (
        actionType === TPlayerActionTypes.RAISE ||
        actionType === TPlayerActionTypes.BET
      ) {
        amount = raiseInputValue;
      }

      await doAction({
        variables: {
          data: {
            tableId,
            userId: id,
            action: actionType,
            amount,
          },
        },
      });
      onPlayerDoAction?.(actionType);
    };

    const availableActions = useMemo(() => {
      const actions: string[] = [];
      if (!currentBet) {
        actions.push("check", "bet");
      } else {
        if (playerBet === currentBet) {
          actions.push("check");
          if (playerStackSize > currentBet && hasAnotherPlayerToAct) {
            actions.push("raise");
          }
        }
        if (playerBet < currentBet) {
          actions.push("call");
          if (
            playerStackSize > currentBet &&
            hasAnotherPlayerToAct &&
            (!lastRaise || !playerRaise || lastRaise >= playerRaise)
          ) {
            actions.push("raise");
          }
        }
      }
      actions.push("fold");
      return actions;
    }, [
      currentBet,
      hasAnotherPlayerToAct,
      lastRaise,
      playerBet,
      playerRaise,
      playerStackSize,
    ]);

    console.log(availableActions);

    const handlePlayerSitDown = async () => {
      if (!playerStackSizeInputValue || !playerNameInputValue) return;
      const userId = uuid();
      await playerSitDown({
        variables: {
          data: {
            tableId,
            userId: userId,
            buyIn: playerStackSizeInputValue,
            seatNumber,
          },
        },
      });
      onPlayerSitDown?.({
        name: playerNameInputValue,
        chips: playerStackSizeInputValue,
        id: userId,
        seatNumber,
      });
    };

    const handlePlayerNameChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setPlayerNameInputValue(event.target.value);
    };

    const handleChipsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (/^[0-9](\d{0,9})?$/.test(value)) {
        setPlayerStackSizeInputValue(Number(value));
      }
    };

    const isUsingBetAction = availableActions.includes("bet");
    const isUsingCallAction = availableActions.includes("call");
    const isUsingRaiseAction = availableActions.includes("raise");
    const isAllowCheckAction = availableActions.includes("check");
    const isAllowBetAction = availableActions.includes("bet");

    useEffect(() => {
      if (!isAllowActions) return;
      setRaiseInputValue(minRaise);
    }, [availableActions, isAllowActions, minRaise]);

    return (
      <Card
        sx={{
          p: 1,
          opacity: slotDisabled ? 0.3 : 1,
          backgroundColor: isPlayerWin ? "yellow" : "#fff",
        }}
      >
        <Label>Seat ID: {seatNumber}</Label>
        <Box sx={{ gap: 1, display: "flex", minHeight: "20px" }}>
          {!!playerBet && (
            <Chip label={playerBet} size="small" color="secondary" />
          )}
          {isDealer && <Chip label="D" size="small" color="success" />}
        </Box>
        <Label>Player ID: {id}</Label>
        <Divider sx={{ pt: 2, pb: 1, fontSize: 12, color: "#999" }}>
          Hold Cards
        </Divider>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <CardView>
            {playerHoldCardsFirst
              ? `${playerHoldCardsFirst?.suitChar}${playerHoldCardsFirst?.rank}`
              : "FOLD"}
          </CardView>
          <CardView>
            {playerHoldCardsSecond
              ? `${playerHoldCardsSecond?.suitChar}${playerHoldCardsSecond?.rank}`
              : "FOLD"}
          </CardView>
        </Box>
        <Box>
          <Label>Stacks</Label>
          <Input
            fullWidth
            placeholder="Enter your chips"
            disabled={!isAllowBuyIn}
            value={playerStackSize || playerStackSizeInputValue}
            onChange={handleChipsChange}
          />
          {isAllowBuyIn ? (
            <>
              <Input
                sx={{ mt: 1 }}
                fullWidth
                placeholder="Enter Player Name"
                disabled={!isAllowBuyIn || slotDisabled}
                value={playerNameInputValue}
                onChange={handlePlayerNameChange}
              />
              <Button
                fullWidth
                sx={{ mt: 1, pt: 1, pb: 1 }}
                variant="contained"
                size="small"
                color="info"
                disabled={
                  playerSitDownLoading ||
                  !isAllowBuyIn ||
                  !playerStackSizeInputValue ||
                  !playerNameInputValue ||
                  slotDisabled
                }
                onClick={handlePlayerSitDown}
              >
                {playerSitDownLoading ? (
                  <CircularProgress size={18} />
                ) : (
                  "SIT DOWN"
                )}
              </Button>
            </>
          ) : (
            <Button
              fullWidth
              sx={{ mt: 1 }}
              variant="contained"
              size="small"
              color="error"
              disabled={!isAllowPlayerLeave}
              // onClick={handlePlayerSitDown}
            >
              Leave Seat
            </Button>
          )}
        </Box>
        <Box>
          <Divider sx={{ pt: 2, pb: 1, fontSize: 12, color: "#999" }}>
            Positive Action
          </Divider>
          <Button
            fullWidth
            sx={{ mt: 1 }}
            variant="contained"
            size="small"
            color="success"
            disabled={
              (!isAllowBetAction && !isUsingCallAction) ||
              !isAllowActions ||
              !isGameStarted ||
              slotDisabled ||
              !id
            }
            onClick={() =>
              handlePlayerAction(
                isUsingCallAction
                  ? TPlayerActionTypes.CALL
                  : TPlayerActionTypes.BET
              )
            }
          >
            {isUsingCallAction ? `Call (${defaultAmount})` : "Bet"}
          </Button>
          <Button
            fullWidth
            sx={{ mt: 1 }}
            variant="contained"
            size="small"
            color="warning"
            disabled={
              (!isUsingBetAction && !isUsingRaiseAction) ||
              !isAllowActions ||
              !isGameStarted ||
              slotDisabled ||
              !id
            }
            onClick={() => {
              if (isUsingRaiseAction) {
                if (raiseInputValue === currentBet) {
                  handlePlayerAction(TPlayerActionTypes.CALL);
                } else {
                  handlePlayerAction(TPlayerActionTypes.RAISE);
                }
              } else if (isUsingBetAction) {
                handlePlayerAction(TPlayerActionTypes.BET);
              }
            }}
          >
            {isUsingRaiseAction ? "RAISE" : isUsingCallAction ? "CALL" : "BET"}{" "}
            ({raiseInputValue})
          </Button>
          <Box sx={{ mt: 1, pl: 1, pr: 1 }}>
            <Slider
              color="warning"
              value={raiseInputValue}
              onChange={(_, newValue) => setRaiseInputValue(newValue as number)}
              disabled={!isGameStarted || slotDisabled || !id}
              defaultValue={minRaise}
              min={minRaise}
              max={playerStackSize || playerStackSizeInputValue}
            />
          </Box>
          <Divider sx={{ pt: 0, pb: 1, fontSize: 12, color: "#999" }}>
            Negative Action
          </Divider>
          <Button
            fullWidth
            sx={{ mt: 1 }}
            variant="contained"
            size="small"
            color="primary"
            disabled={
              !isAllowCheckAction ||
              !isAllowActions ||
              !isGameStarted ||
              slotDisabled ||
              !id
            }
            onClick={() => handlePlayerAction(TPlayerActionTypes.CHECK)}
          >
            Check
          </Button>
          <Button
            fullWidth
            sx={{ mt: 1 }}
            variant="contained"
            size="small"
            color="error"
            disabled={!isAllowActions || !isGameStarted || slotDisabled || !id}
            onClick={() => handlePlayerAction(TPlayerActionTypes.FOLD)}
          >
            Fold
          </Button>
        </Box>
      </Card>
    );
  }
);

export default Player;
