import { useState, useMemo, useCallback, useEffect } from "react";
import { styled as MuiStyled } from "@mui/material/styles";
import { useMutation, useLazyQuery } from "@apollo/client";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MuiDivider from "@mui/material/Divider";

import { CREATE_TABLE } from "./gql/table/createTable";
import { DEAL_CARDS } from "./gql/table/dealCards";
import { GET_TABLE } from "./gql/table/getTable";

import Player, { type TSeatData } from "./Player";

const ControlButton = MuiStyled(Button)(() => ({
  "&.MuiButtonBase-root ": {
    "&.Mui-disabled": {
      color: "rgba(255, 255, 255, 0.5)",
      backgroundColor: "rgba(255, 255, 255, 0.12)",
    },
  },
}));

const Divider = MuiStyled(MuiDivider)(() => ({
  "&.MuiDivider-root": {
    paddingTop: 2,
    paddingBottom: 1,
    fontSize: 12,
    color: "#fff",
    "&:before": {
      backgroundColor: "#fff",
      height: "1px",
    },
    "&:after": {
      backgroundColor: "#fff",
      height: "1px",
    },
  },
}));

const InputTextField = MuiStyled(TextField)(() => ({
  flex: "auto",
  "& > label": {
    color: "#fff",
    "&.Mui-focused": {
      color: "#fff",
    },
  },
  "& .MuiOutlinedInput-root": {
    overflow: "hidden",
    borderRadius: 4,
    color: "#fff",
    // border: "1px solid #fff",
    backgroundColor: "#555",
    "&:hover": {
      backgroundColor: "#555",
    },
    "&.Mui-focused": {
      // backgroundColor: "transparent",
      border: "none",
      color: "#fff",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "8px 0 8px 16px",
  },
}));

const BoardCard = MuiStyled(Card)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "8px",
  color: "#333",
  width: "40px",
  height: "50px",
}));

const TableInfo = MuiStyled(Card)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: "16px",
  paddingLeft: "16px",
  color: "#333",
}));

const defaultData = {
  id: -1,
  name: "",
  chips: undefined,
  handCards: [],
  bigBlind: 0,
  smallBlind: 0,
  isDealer: false,
};

function App() {
  const [isGameOn, setIsGameOn] = useState(false);
  const [tableId, setTableId] = useState<string>();
  const [tableData, setTableData] = useState<any>();
  const [playerSeatsData, setPlayerSeatsData] = useState<
    (TSeatData | undefined)[]
  >([...Array(10)]);

  const [createTable] = useMutation(CREATE_TABLE, {
    onCompleted: (data) => {
      setTableId(data.createTable);
    },
  });

  const [dealCards] = useMutation(DEAL_CARDS, {
    onCompleted: (data) => {
      setTableData(data.dealCards);
    },
  });

  // const {
  //   loading: isGetTableDataLoading,
  //   startPolling,
  //   stopPolling,
  // } = useQuery(GET_TABLE, {
  //   variables: { tableId },
  //   skip: !tableId,
  //   onCompleted: (data) => {
  //     setTableData(data.getTable);
  //   },
  // });

  const [executeQueryTable] = useLazyQuery(GET_TABLE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setTableData(data.getTable);
    },
  });

  const bigBlind = tableData?.bigBlind || 0;
  const smallBlind = tableData?.smallBlind || 0;
  const currentRound = tableData?.currentRound || 0;
  const currentBet = tableData?.currentBet || 0;
  const communityCards = tableData?.communityCards;
  // const handNumber = tableData?.handNumber || -1;

  const currentPot = useMemo(() => {
    const pots = tableData?.pots;
    if (!pots) return 0;
    return pots[0]?.amount || 0;
  }, [tableData?.pots]);

  const currentRoundWinnerId = useMemo(() => {
    const winners = tableData?.winners;
    if (!winners) return "";
    return winners[0]?.id;
  }, [tableData?.winners]);

  const isGameReady = useMemo(() => {
    const buyInUsers = playerSeatsData.filter((d) => d);
    return buyInUsers.length >= 2 && !!tableId;
  }, [playerSeatsData, tableId]);

  const availableActingPlayers = useMemo(() => {
    if (!tableData?.players) return [];
    return tableData.players.filter(
      (player: any) =>
        player &&
        !player.folded &&
        player.stackSize > 0 &&
        (!currentBet ||
          !player.raise ||
          (currentBet && player.bet < currentBet))
    );
  }, [currentBet, tableData?.players]);

  const handleUpdateTableId = useCallback(async () => {
    await createTable();
  }, [createTable]);

  const handlePlayerDoAction = useCallback(
    async (actionType: string) => {
      await executeQueryTable({ variables: { id: tableId } });
    },
    [executeQueryTable, tableId]
  );

  const handleGameStart = useCallback(async () => {
    await dealCards({ variables: { tableId } });
    setIsGameOn(true);
  }, [dealCards, tableId]);

  const handlePlayerSitDown = useCallback(
    (nextPlayerData: TSeatData) => {
      const newPlayerSeats = [...playerSeatsData];
      const playerData = newPlayerSeats[nextPlayerData.seatNumber];
      newPlayerSeats[nextPlayerData.seatNumber] = {
        ...defaultData,
        ...playerData,
        ...nextPlayerData,
      };
      setPlayerSeatsData(newPlayerSeats);
    },
    [playerSeatsData]
  );

  const seats = useMemo(
    () =>
      playerSeatsData.map((seatData, index) => {
        const players = tableData?.players || [];
        const isPlayerTurn = tableData?.currentPosition === index;
        console.log(seatData?.id);
        const isPlayerWin = currentRoundWinnerId === seatData?.id;
        return (
          <Player
            key={`seat-${index}-empty`}
            id={seatData?.id}
            seatNumber={index}
            tableId={tableId}
            playerOriginData={players?.[index] || null}
            isGameStarted={isGameOn}
            currentBet={currentBet}
            availableActingPlayers={availableActingPlayers}
            lastRaise={tableData?.lastRaise || 0}
            onPlayerSitDown={handlePlayerSitDown}
            onPlayerDoAction={handlePlayerDoAction}
            isPlayerWin={isPlayerWin}
            isDealer={
              !currentRoundWinnerId && tableData?.dealerPosition === index
            }
            bigBlind={bigBlind}
            smallBlind={smallBlind}
            isAllowActions={isPlayerTurn}
            isBigBlindPosition={tableData?.bigBlindPosition === index}
            isSmallBlindPosition={tableData?.smallBlindPosition === index}
          />
        );
      }),
    [
      availableActingPlayers,
      bigBlind,
      currentBet,
      currentRoundWinnerId,
      handlePlayerDoAction,
      handlePlayerSitDown,
      isGameOn,
      playerSeatsData,
      smallBlind,
      tableData?.bigBlindPosition,
      tableData?.currentPosition,
      tableData?.dealerPosition,
      tableData?.lastRaise,
      tableData?.players,
      tableData?.smallBlindPosition,
      tableId,
    ]
  );
  const isNextHandAvailable = !!currentRoundWinnerId;

  useEffect(() => {
    if (isNextHandAvailable) {
      setIsGameOn(false);
    }
  }, [isNextHandAvailable]);
  // useEffect(() => {
  //   if (isGameOn) {
  //     // startPolling(POLLING_INTERVAL);
  //   }

  //   return () => {
  //     stopPolling();
  //   };
  // }, [isGameOn, startPolling, stopPolling]);

  const boardCards = useMemo(() => {
    if (!communityCards) return [];
    return communityCards.map((card: any, index: number) => (
      <BoardCard key={`board-card-${card.suitChar}${card.rank}`}>
        {card.rank}
        {card.suitChar}
      </BoardCard>
    ));
  }, [communityCards]);

  return (
    <Container maxWidth="xl" sx={{ pb: 4 }}>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: "column",
        }}
      >
        <Box sx={{ gridArea: "header", mt: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <InputTextField
              sx={{ flex: 1 }}
              fullWidth
              variant="outlined"
              value={tableId}
              placeholder="Generated Table ID First..."
              InputProps={{ readOnly: true }}
            />
            <ControlButton
              sx={{ ml: 2 }}
              variant="contained"
              size="large"
              disabled={!!tableId}
              onClick={handleUpdateTableId}
            >
              Create Table
            </ControlButton>
          </Box>
        </Box>
        <Divider>Game Dashboard</Divider>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <ControlButton
              disabled={isGameOn || !isGameReady}
              variant="contained"
              size="large"
              color="warning"
              onClick={handleGameStart}
            >
              {isNextHandAvailable ? "Next Hand" : "Game Start"}
            </ControlButton>
            <ControlButton
              disabled={!isGameOn}
              variant="contained"
              size="large"
              color="info"
            >
              Pause
            </ControlButton>
            <ControlButton
              disabled={!isGameOn}
              variant="contained"
              size="large"
              color="error"
            >
              Stop
            </ControlButton>
            {!!currentBet && (
              <TableInfo>Total: {currentPot + currentBet}</TableInfo>
            )}
            <TableInfo>Pot: {currentPot}</TableInfo>
            {!!tableData?.handNumber && (
              <TableInfo>Rounds: {tableData?.handNumber}</TableInfo>
            )}
            <TableInfo>Game Mode: NHL</TableInfo>
            <TableInfo>
              BB/SB: {bigBlind}/{smallBlind}
            </TableInfo>
            <TableInfo>Game Stage: {currentRound || "not started"}</TableInfo>
          </Box>
        </Box>
        <Divider>Board Cards</Divider>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: 1, minHeight: "40px" }}>
            {boardCards}
          </Box>
        </Box>
        <Divider>Players</Divider>
        <Box>
          <Box
            sx={{
              display: "grid",
              gap: 2,
              gridTemplateColumns: "repeat(5, 1fr)",
            }}
          >
            {seats}
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default App;
