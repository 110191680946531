import { gql } from "@apollo/client";

export const SIT_DOWN = gql`
  mutation sitDown($data: sitDownInput) {
    sitDown(data: $data) {
      id
      owner
      buyIn
      smallBlind
      bigBlind
      autoMoveDealer
      bigBlindPosition
      smallBlindPosition
      dealerPosition
      lastPosition
      communityCards {
        rank
        suit
        color
        suitChar
      }
      deck {
        rank
        suit
        color
        suitChar
      }
      currentBet
      currentPosition
      currentRound
      debug
      handNumber
      lastRaise
      players {
        id
        stackSize
        bet
        raise
        holeCards {
          rank
          suit
          color
          suitChar
        }
        folded
        showCards
        left
      }
      pots {
        amount
        eligiblePlayers {
          id
          stackSize
          bet
          raise
          holeCards {
            rank
            suit
            color
            suitChar
          }
          folded
          showCards
          left
        }
        winners {
          id
          stackSize
          bet
          raise
          holeCards {
            rank
            suit
            color
            suitChar
          }
          folded
          showCards
          left
        }
      }
      winners {
        id
        stackSize
        bet
        raise
        holeCards {
          rank
          suit
          color
          suitChar
        }
        folded
        showCards
        left
      }
    }
  }
`;
