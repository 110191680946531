import React from "react";
import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { ApolloProvider } from "@apollo/client";
import createApolloClient from "./gql/client";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <CssBaseline />
    <GlobalStyles
      styles={{
        body: {
          fontFamily: "'Noto Sans', 'Roboto', sans-serif",
          backgroundColor: "#323338",
          color: "#ffffff",
        },
      }}
    />
    <ApolloProvider client={createApolloClient()}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);
