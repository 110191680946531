import { ApolloClient, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { cache } from "./cache";

const {
  REACT_APP_GRAPHQL_SERVER_URL,
  REACT_APP_CF_ACCESS_CLIENT_ID,
  REACT_APP_CF_ACCESS_CLIENT_SECRET,
} = process.env;
const createApolloClient = () => {
  const httpLink = createHttpLink({
    uri: REACT_APP_GRAPHQL_SERVER_URL,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      ...headers,
      "CF-Access-Client-Id": REACT_APP_CF_ACCESS_CLIENT_ID,
      "CF-Access-Client-Secret": REACT_APP_CF_ACCESS_CLIENT_SECRET,
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
  });
};

export default createApolloClient;
